<template>
  <div class="main">
    <div class="container">
      <div class="judges_header">
        <div
          class="f_btn"
          :class="item == act_str ? 'act' : ''"
          v-for="(item, index) in Initial"
          :key="index"
          @click="changeList(item)"
        >
          {{ item }}
        </div>
      </div>
      <div class="judges_con">
        <JudgeList :data="list" :cat="'Speaks'"></JudgeList>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_SPEAKERS } from '@/api/index.js';
import JudgeList from '@/components/JudgeList.vue';
export default {
  name: 'Judges',
  components: { JudgeList },
  data() {
    return {
      act_str: '',
      Initial: [], //首字母导航
      olist: [], //原始数据。所有的
      list: [], //用于使用数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getInitial() {
      this.list.forEach(item => {
        let name = item.first_name.substr(0, 1).toUpperCase();
        console.log(name);
        if (this.Initial.indexOf(name) == -1) {
          this.Initial.push(name);
        }
      });
      //排个序
      this.Initial.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      console.log(this.Initial);
    },
    getList() {
      //第一次进来获取数据。只获取一次
      this.list = [];
      GET_SPEAKERS().then(res => {
        console.log(res);
        this.olist = res.data;

        this.list = res.data;

        this.getInitial();
      });
    },
    changeList(str) {
      this.act_str = str;
      this.list = [];
      //搜字母
      this.olist.forEach(item => {
        let name = item.first_name.substr(0, 1).toUpperCase();
        if (name == this.act_str) {
          this.list.push(item);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 950px) {
  .judges_header {
    display: none;
  }
}
</style>
